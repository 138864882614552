import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

export const HeroContainer = styled.div`
    background: #ffffff;
    display: flex;
    position: relative;
    height: 75vh;
    overflow: hidden;

    @media screen and (max-height: 1200px) {
        height: 80vh;
    }

    @media screen and (max-height: 940px) {
        height: 90vh;
    }

    @media screen and (max-height: 865px) {
        height: 100vh;
    }

    @media screen and (max-height: 635px) {
        height: 110vh;
    }

    @media screen and (max-height: 455px) {
        height: 150vh;
    }

    @media screen and (max-width: 480px) {
        height: 70vh;
    }
`

export const SlideWrapper = styled.div`
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`

export const SlideIMG = styled.img`
    position: absolute;
    max-width: 100%;
    min-height: 100%;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    z-index: -10;
`

export const TextWrapper = styled.div`
    display: flex;
    position: relative;
    margin: 0 auto;
    padding: 0 24px;
    height: 100%;
    max-width: 1400px;
    align-items: center;

    @media screen and (max-width: 1400px) {
        padding: 0 8px;
		margin-left: 1rem;
	}
`

export const SlideText = styled.div`
    display: block;
    width: 53%;

    @media screen and (max-width: 480px) {
        padding-right: 1rem;
        width: 45%;
	}
`

export const SlideHeader = styled.h1`
    font-size: 48px;
    font-weight: bold;
    padding-bottom: .5rem;

    @media screen and (max-width: 768px) {
		font-size: 40px;
	}

    @media screen and (max-width: 480px) {
        font-size: 24px;
	}

    @media screen and (max-width: 370px) {
        font-size: 16px;
    }
`

export const SlideSubHeader = styled.h2`
    font-size: 24px;
    padding-bottom: 2rem;

    @media screen and (max-width: 768px) {
		font-size: 24px;
	}

	@media screen and (max-width: 480px) {
		font-size: 16px;
        padding-bottom: 1.5rem;
	}

    @media screen and (max-width: 370px) {
        font-size: 12px;
        padding-bottom: 1rem;
    }
`

export const SlideButton = styled(LinkR)`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    background-color: #f0f0f0;
    background: transparent;
    text-decoration: none;
    color: #000000;
    border: 4px solid #000000;
    border-radius: 18px;
    padding: 8px 24px 8px 24px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
		font-size: 24px;
	}

	@media screen and (max-width: 480px) {
		font-size: 16px;
        padding: 6px 12px 6px 12px;
	}

    @media screen and (max-width: 370px) {
        font-size: 12px;
        padding: 3px 9px 3px 9px;
    }
`

export const IndicatorWrapper = styled.div`
    display: flex;
    position: relative;
    margin: 75vh auto;
    width: 10rem;
    justify-content: center;
    align-items: center;
    bottom: 3vh;

    @media screen and (max-height: 1200px) {
        margin: 80vh auto;
    }

    @media screen and (max-height: 940px) {
        margin: 90vh auto;
    }

    @media screen and (max-height: 865px) {
        margin: 100vh auto;
    }

    @media screen and (max-height: 635px) {
        margin: 110vh auto;
    }

    @media screen and (max-height: 455px) {
        margin: 150vh auto;
    }

    @media screen and (max-width: 480px) {
        margin: 70vh auto;
    }
`

