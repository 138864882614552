export const heroObjOne = {
    slides: [
    {
        id: 1,
        src: require('../../images/odu_bg_main2.png'),
        alt: 'ATTOCHRON',
        header: "Telco's Missing Link",
        subheader: "World's Only Carrier-Grade Optical Wireless Communications\u2122",
        button: "See Product",
        link: require('../../docs/Attochron_ALTIS-7_Specifications 041524.pdf'),
        target: '_blank',
    },
    {
        id: 2,
        src: require('../../images/tech_scl3.png'),
        alt: 'ATTOCHRON',
        header: "Why Attochron Is Better",
        // subheader: "Attochron Raises the FSOC Bar to Carrier-Grade",
        button: "Tech Briefs",
        link: '/tech',
        // link: require('../../docs/2024 Attochron - FSOC with Broadband Optical Sources rev070924.pdf'),
        target: '',
    },
    {
        id: 3,
        src: require('../../images/owc_towers.jpg'),
        alt: 'ATTOCHRON',
        header: "Attochron Use Cases",
        subheader: "Enterprise Access, Cell Tower Backhaul, MDU, DR, Federal & Other Needs",
        button: "Learn More",
        link: require('../../docs/2024 Attochron FSOC - The Business Potential of Free Space Optics Communications rev070924.pdf'),
        target: '_blank',
    },
    {
        id: 4,
        src: require('../../images/press_bg4.png'),
        alt: 'ATTOCHRON',
        header: "Attochron News",
        // subheader: "Attochron GTM for Carrier-Grade FSOC Product",
        button: "View News",
        link: '/news',
        target: '',
    },
    {
        id: 5,
        src: require('../../images/team_bg.png'),
        alt: 'ATTOCHRON',
        header: "Unparalleled Team",
        subheader: "Team, Labs, Partners, Principles",
        button: "About Us",
        link: '/about',
        target: '',
    },
    {
        id: 6,
        src: require('../../images/ind_bg_main2.png'),
        alt: 'ATTOCHRON',
        header: "Industry News",
        // subheader: "Learn About the Team, Labs, and Principles Guiding Attochron",
        button: "View News",
        link: '/industry',
        target: '',
    }
]
};