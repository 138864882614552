export const pressObjOne = {
    title: "Attochron News",
    target: "_blank",

    pressHeader2: "September 18, 2024 - Lumen Technologies likes free space optical tech from Attochron",
    pressSubheader2: "Lumen Technologies is buying free space optical technology from Attochron and says Attochron's ultra short pulsed laser solves... ",
    pressButton2: "Read More",
    pressLink2: 'https://www.fierce-network.com/broadband/lumen-technologies-likes-free-space-optical-tech-attochron',

    pressHeader1: "July 17, 2024 - Attochron Completes $15M Series-A Funding",
    pressSubheader1: "Attochron LLC, the leader in providing carrier-grade optical wireless equipment, has closed $15M in Series A funding with a leading... ",
    pressButton1: "Read More",
    pressLink1: require('../../docs/2024 Attochron - TCR. Inc. PE Series A - Press Release - 090524.pdf'),
}