import React, { useState, useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Nav, NavbarContainer, NavLogo, NavTextWrapper, NavText, NavImg, NavMenu, NavItem, NavLink, MobileIcon, OpenIcon, IconText } from './NavbarElements';

const Navbar = ({ toggle, img1, alt1, productPDF }) => {
    const [scrollNav, setScrollNav] = useState(false)

	const changeNav = ()=> {
		if(window.scrollY >= 80) {
			setScrollNav(true)
		} else {
			setScrollNav(false)
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', changeNav)
	}, [])

    const toggleHome = () => {
		scroll.scrollToTop();
	}

    return (
        <>
            <Nav scrollNav={scrollNav}>
                <NavbarContainer>
                    <NavTextWrapper>
                        <NavText>WORLDS ONLY CARRIER-GRADE OPTICAL WIRELESS COMMUNICATIONS</NavText>
                    </NavTextWrapper>
                    <NavLogo to='/' onClick={toggleHome}><NavImg src={img1} alt={alt1}/></NavLogo>
                    <MobileIcon onClick={toggle}>
                        <IconText>Menu</IconText><OpenIcon />
                    </MobileIcon>
                    <NavMenu>
                        <NavItem>
                            <NavLink to={productPDF} target="_blank">Product</NavLink> 
                        </NavItem>
                        <NavItem>
                            <NavLink to='/tech'>Tech</NavLink> 
                        </NavItem>
                        <NavItem>
                            <NavLink to='/news'>News</NavLink> 
                        </NavItem>
                        <NavItem>
                            <NavLink to='/about'>About Us</NavLink> 
                        </NavItem>
                    </NavMenu>
                </NavbarContainer>
            </Nav>
            
        </>
    )
}

export default Navbar