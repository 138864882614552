import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { Link as LinkR } from 'react-router-dom';

export const SidebarContainer = styled.aside`
    position: fixed;
    z-index: 3;
    width: 100%;
    height: 100%;
    background: #d3d3d3;
    display: grid;
    align-items: center;
    top: 0;
    left: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
    top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
    overflow: hidden;
`

export const Icon = styled.div`
    display: flex;
    position: absolute;
    top: 1.2rem;
    right: 1.5rem;
    background: transparent;
    border: 2px solid #000000;
    border-radius: 10px;
    padding: 8px 8px 8px 8px;
    cursor: pointer;
    outline: none;
`

export const CloseIcon = styled(FaTimes)`
    color: #000000;
    font-size: 1.3rem;
`

export const IconText = styled.h1`
    color: #000000;
    font-size: 1.2rem;
    margin-right: 0.4rem;
`

export const SidebarWrapper = styled.div`
    color: #000000;
`

export const SidebarMenu = styled.ul`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 80px);
    text-align: center;
    margin-top: -2rem;

    @media screen and (max-width: 480px) {
		grid-template-rows: repeat(4, 60px);
	}
`

export const SidebarLink = styled(LinkR)`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none;
    list-styled: none;
    transition: 0.2s ease-in-out;
    color: #000000;
    cursor: pointer;

    &:hover {
        font-weight: bold;
        ${'' /* transition: 500ms ease-in-out; */}
    }
`