import React from 'react';
import { PressContainer, TitleWrapper, Title, PressWrapper, ReleaseWrapper, Header, Subheader, Button } from './PressElements';

const PressSection = ({ 
    title, target,
    pressHeader1, pressSubheader1, pressButton1, pressLink1,
    pressHeader2, pressSubheader2, pressButton2, pressLink2,
}) => {
    
    return (
        <>
            <PressContainer>
                <TitleWrapper>
                    <Title>
                        {title}
                    </Title>
                </TitleWrapper>
                <PressWrapper>
                    <ReleaseWrapper>
                        <Header>{pressHeader2}</Header>
                        <Subheader>{pressSubheader2}<Button to={pressLink2} target={target}>{pressButton2}</Button></Subheader>
                    </ReleaseWrapper>
                    <ReleaseWrapper>
                        <Header>{pressHeader1}</Header>
                        <Subheader>{pressSubheader1}<Button to={pressLink1} target={target}>{pressButton1}</Button></Subheader>
                    </ReleaseWrapper>
                </PressWrapper>
            </PressContainer>
        </>
    )
}

export default PressSection;