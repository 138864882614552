import React from 'react';
import { SidebarContainer, SidebarWrapper, SidebarMenu, SidebarLink } from './SidebarElements'

const Sidebar = ({ isOpen, toggle, productPDF }) => {

    return (
        <SidebarContainer isOpen={isOpen} onClick={toggle}>
            <SidebarWrapper>
                <SidebarMenu>
                    <SidebarLink to={productPDF} target='_blank' onClick={toggle}>Product</SidebarLink>
                    <SidebarLink to="/tech" onClick={toggle}>Tech</SidebarLink>
                    <SidebarLink to="/news" onClick={toggle}>News</SidebarLink>
                    <SidebarLink to="/about" onClick={toggle}>About Us</SidebarLink>
                </SidebarMenu>
            </SidebarWrapper>
        </SidebarContainer>
    )
}

export default Sidebar